<template>
	<b-row>
		<b-col
		md="11"
		lg="5">
			<logo></logo>	
			<phone></phone>	
			<name></name>	
		</b-col>
	</b-row>
</template>
<script>
// Components
import Logo from '@/components/buyer/confirm-order/components/Logo'
import Phone from '@/components/buyer/confirm-order/components/Phone'
import Name from '@/components/buyer/confirm-order/components/Name'
export default {
	components: {
		Logo,
		Phone,
		Name,
	},
}
</script>