<template>
	<div 
	v-if="view == 'nombre'"
	class="p-20">
		<b-form-group
		label="Nombre y Apellido">
			<b-form-input
			v-model="buyer.name"
			placeholder="Ingrese su numero nombre y apellido"></b-form-input>
		</b-form-group>
		<b-form-group
		label="Email"
		description="Lo usaremos para informarle las sobre el estado de su pedido">
			<b-form-input
			v-model="buyer.email"
			placeholder="Ingrese su correo electronico"></b-form-input>
		</b-form-group>
		<b-form-group
		label="Aclaraciones">
			<b-form-textarea
			v-model="description"
			placeholder="Ingrese alguna aclaración que crea necesaria para su pedido"></b-form-textarea>
		</b-form-group>
		<p>
			{{ error }}
		</p>
		<btn-loader
		@clicked="next"
		:loader="loading"
		text="Enviar pedido"></btn-loader>
	</div>
</template>
<script>
import BtnLoader from '@/common-vue/components/BtnLoader'

import order from '@/mixins/order'
export default {
	mixins: [order],
	components: {
		BtnLoader,
	},
	data() {
		return {
			loading: false,
			error: '',
		}
	},
	methods: {
		next() {
			if (this.check() && !this.loading) {
				this.loading = true 
				this.$api.post('buyers', this.buyer)
				.then(res => {
					this.loading = false 
					this.$store.commit('order/setBuyer', res.data.buyer)
					this.makeOrder()
				})
				.catch(err => {
					this.loading = false 
					console.log(err)
					this.error = error
				})
			}
		},
		check() {
			if (this.buyer.name == '') {
				this.$toast.error('Ingrese su nombre y apellido por favor')
				return false 
			}
			return true
		}
	}
}
</script>