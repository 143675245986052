<template>
	<div 
	v-if="view == 'telefono'"
	class="p-20">
		<b-form-group
		label="Numero de telefono"
		description="Usaremos su numero telefonico para comunicarnos con usted via WhatsApp ante cualquier irregularidad.">
			<b-form-input
			v-model="buyer.phone"
			@keyup.enter="checkBuyer"
			placeholder="Ingrese su numero de telefono"></b-form-input>
		</b-form-group>
		<btn-loader
		@clicked="checkBuyer"
		:loader="loading"
		text="Siguiente"></btn-loader>
	</div>
</template>
<script>
import BtnLoader from '@/common-vue/components/BtnLoader'

import order from '@/mixins/order'
export default {
	mixins: [order],
	components: {
		BtnLoader,
	},
	data() {
		return {
			loading: false,
		}
	},
	methods: {
		checkBuyer() {
			if (this.check() && !this.loading) {
				this.loading = true 
				this.$api.get('buyers/'+this.buyer.phone)
				.then(res => {
					this.loading = false 
					if (res.data.buyer) {
						this.$store.commit('order/setBuyer', res.data.buyer)
					}
					this.$router.push({name: this.route_name, params: {view: 'nombre'}})
				})
			}
		},
		check() {
			if (this.buyer.phone == '') {
				this.$toast.error('Ingrese su numero de telefono por favor')
				return false 
			}
			return true
		}
	}
}
</script>